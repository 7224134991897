<template>
  <b-datetimepicker
    :value="localeToZonedTime(value)"
    :placeholder="placeholder"
    :icon="icon"
    :min-datetime="localeToZonedTime(minDatetime)"
    :max-datetime="localeToZonedTime(maxDatetime)"
    editable
    locale="fr-FR"
    @input="input"
  />
</template>

<script>
import { zonedTimeToUtc, format, utcToZonedTime } from 'date-fns-tz'

export default {
  name: 'ODateTimePicker',

  props: {
    value: {
      type: Date,
    },
    minDatetime: {
      type: Date,
      default: null,
    },
    maxDatetime: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Type or select a date...',
    },
    icon: {
      type: String,
      default: 'calendar-day',
    },
    timezone: {
      type: String,
      default: 'Europe/Paris',
    },
  },

  methods: {
    localeToZonedTime(date) {
      if (!(date instanceof Date)) {
        return undefined
      }

      const zonedDate = utcToZonedTime(date, this.timezone)
      const formattedDate = format(zonedDate, 'yyyy-MM-dd HH:mm:ss')

      return new Date(formattedDate)
    },

    input(date) {
      if (!(date instanceof Date)) {
        this.$emit('input', null)
        return
      }

      const utcDate = zonedTimeToUtc(date, this.timezone)
      const isoDateString = format(utcDate, 'yyyy-MM-dd HH:mm:ss')

      this.$emit('input', new Date(isoDateString))
    },
  },
}
</script>
