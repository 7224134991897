<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-field
      label="Formulaire"
      :message="fields.liveSetupForm.error"
      :type="fields.liveSetupForm.error !== null ? 'is-danger' : ''"
    >
      <div v-if="liveSetupForms.length === 0 && !loading">Aucun formulaire trouvé</div>
      <b-select
        v-else
        v-model="fields.liveSetupForm.value"
        expanded
        :loading="loading"
        :disabled="loading"
        placeholder="Choisissez un formulaire"
      >
        <option
          v-for="liveSetupForm in liveSetupForms"
          :key="liveSetupForm.id"
          :value="liveSetupForm"
        >
          {{ liveSetupForm.name }}
        </option>
      </b-select>
    </b-field>
    <b-field v-show="liveSetupFormSubmissions.length > 0" label="Soumission">
      <b-select
        v-model="fields.liveSetupFormSubmission.value"
        expanded
        :loading="loading"
        :disabled="loading"
        placeholder="Choisissez une soumission du formulaire"
      >
        <option
          v-for="liveSetupFormSubmission in liveSetupFormSubmissions"
          :key="liveSetupFormSubmission.id"
          :value="liveSetupFormSubmission"
        >
          {{ liveSetupFormSubmission.name }}
        </option>
      </b-select>
      <p class="control">
        <a
          v-if="fields.liveSetupFormSubmission.value"
          :href="fields.liveSetupFormSubmission.value.editUrl"
          target="_blank"
        >
          <b-button type="is-primary" outlined label="Modifier le formulaire" />
        </a>
      </p>
    </b-field>
    <div v-if="liveSetupFormSubmission" class="m-5">
      <o-message
        v-if="submissionErrorMessages.length > 0"
        :errorMessages="submissionErrorMessages"
        title="Formulaire invalide"
        :closable="false"
      />
      <live-setup-step
        v-else
        :errorMessages="submitErrorMessages"
        :live="live"
        :liveSettings="liveSettings"
        :liveStages="liveStages"
        :liveCategories="liveCategories"
        :liveAlertContacts="liveAlertContacts"
      />
    </div>
    <div class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button
            v-if="!live"
            native-type="submit"
            type="is-primary"
            :loading="loading"
            :disabled="disabledSubmit"
            label="Importer un live"
          />
          <b-button
            v-else
            type="is-success"
            label="Voir le live"
            @click="
              $router.push({
                name: 'live.edit.informations',
                params: { id: live.id },
              })
            "
          />
        </p>
      </div>
      <div class="level-right">
        <p class="level-item">
          <b-button
            v-if="submissionErrorMessages.length > 0 && fields.liveSetupFormSubmission.value"
            class="mr-0"
            label="Rafraîchir"
            :loading="loading"
            :disabled="loading"
            @click="refreshLiveSetupFormSubmission(fields.liveSetupFormSubmission.value.id)"
          />
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import OMessage from '@components/Message.vue'
import { getInvalidSubmissionErrorMessages } from '@views/pages/LiveSetupFormPage/getInvalidSubmissionErrorMessages.js'
import LiveSetupStep from '@views/pages/LiveSetupFormPage/LiveSetupStep/index.vue'
import { zonedTimeToUtc } from 'date-fns-tz'
import { MAP_LAYER_OSM } from '@constants/settings/mapLayer'

export default {
  name: 'LiveSetupForm',

  components: { LiveSetupStep, OMessage },

  data() {
    return {
      loading: false,
      liveSetupForms: [],
      liveSetupFormSubmissions: [],
      liveSetupFormSubmission: null,
      submissionErrorMessages: [],
      fields: {
        liveSetupForm: {
          value: null,
          error: null,
        },
        liveSetupFormSubmission: {
          value: null,
        },
      },
      submitErrorMessages: null,
      live: null,
      liveSettings: null,
      liveAlertContacts: [],
      liveStages: [],
      liveCategories: [],
    }
  },

  async created() {
    await this.fetchLiveSetupForms()
  },

  watch: {
    async 'fields.liveSetupForm.value'(form) {
      this.liveSetupFormSubmission = null
      this.fields.liveSetupFormSubmission.value = null

      if (form) {
        await this.fetchLiveSetupFormSubmissions(form.id)
      }
    },

    async 'fields.liveSetupFormSubmission.value'(submission) {
      if (submission) {
        await this.refreshLiveSetupFormSubmission(submission.id)
      }
    },

    liveSetupFormSubmissions(liveSetupFormSubmissions) {
      this.fields.liveSetupForm.error =
        liveSetupFormSubmissions?.length === 0 ? 'Aucune soumission pour ce formulaire' : null
    },
  },

  computed: {
    disabledSubmit() {
      return (
        this.loading ||
        this.submissionErrorMessages.length > 0 ||
        this.live ||
        Object.values(this.fields).find((field) => !field.value || field.error)
      )
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true
      this.submitErrorMessages = null

      try {
        await this.createLive()

        if (this.live) {
          await this.fetchDefaultLiveSettings()
          await this.createLiveSettings()
          await this.createLiveStages()
          await this.createLiveCategories()
          await this.createLiveAlertContacts()
        }
      } catch (err) {
        console.error(err)
      }

      this.loading = false
    },

    async createLive() {
      try {
        this.live = await this.$services.liveService.create({
          ...this.liveSetupFormSubmission.live,
          startedAt: new Date(this.liveSetupFormSubmission.live.startedAt),
          endedAt: new Date(this.liveSetupFormSubmission.live.endedAt),
        })
      } catch (err) {
        console.error(err)
        this.submitErrorMessages = {
          ...this.submitErrorMessages,
          live: err.message,
        }
      }
    },

    async fetchDefaultLiveSettings() {
      try {
        this.liveSettings = await this.$services.liveSettingsService.getByLiveId(this.live.id)
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }
    },

    async createLiveSettings() {
      try {
        this.liveSettings = await this.$services.liveSettingsService.update(this.live.id, {
          ...this.liveSettings,
          coordinateFormat: this.liveSetupFormSubmission.settings.coordinateFormat,
          defaultPublicMapLayer: MAP_LAYER_OSM,
          defaultPrivateMapLayer: this.liveSetupFormSubmission.settings.defaultMapLayer,
          vehicleInactivityTimeout: this.liveSetupFormSubmission.settings.vehicleInactivityTimeout,
        })
      } catch (err) {
        console.error(err)
        this.submitErrorMessages = {
          ...this.submitErrorMessages,
          liveSettings: err.message,
        }
      }
    },

    async createLiveStages() {
      this.liveStages = (
        await Promise.all(
          this.liveSetupFormSubmission.stages.map(async (stage, index) => {
            try {
              const startedAt = zonedTimeToUtc(stage.startedAt + ' 00:00:00', this.live.timezone)
              const endedAt = zonedTimeToUtc(stage.endedAt + ' 23:59:59', this.live.timezone)

              return await this.$services.liveStageService.create({
                ...stage,
                liveId: this.live.id,
                startedAt,
                endedAt,
              })
            } catch (err) {
              console.error(err)
              this.submitErrorMessages = {
                ...this.submitErrorMessages,
                liveStages: {
                  ...this.submissionErrorMessages.stages,
                  [index]: err.message,
                },
              }
            }
          }),
        )
      ).filter((stage) => stage)
    },

    async createLiveCategories() {
      const defaultSpareCategory = [
        {
          liveId: this.live.id,
          name: 'SPARE',
          accessType: 'private',
          icon: 'user',
          color: 'gray',
          useCheckpoint: false,
        },
      ]

      this.liveCategories = (
        await Promise.all(
          [...this.liveSetupFormSubmission.categories, ...defaultSpareCategory].map(
            async (category, index) => {
              try {
                return await this.$services.liveCategoryService.create({
                  ...category,
                  liveId: this.live.id,
                })
              } catch (err) {
                console.error(err)
                this.submitErrorMessages = {
                  ...this.submitErrorMessages,
                  liveCategories: {
                    ...this.submissionErrorMessages.stages,
                    [index]: err.message,
                  },
                }
              }
            },
          ),
        )
      ).filter((category) => category)
    },

    async createLiveAlertContacts() {
      this.liveAlertContacts = (
        await Promise.all(
          this.liveSetupFormSubmission.alertContacts.map(async (alertContact, index) => {
            try {
              return await this.$services.liveAlertContactService.create({
                ...alertContact,
                liveId: this.live.id,
              })
            } catch (err) {
              console.error(err)
              this.submitErrorMessages = {
                ...this.submitErrorMessages,
                liveAlertContacts: {
                  ...this.submissionErrorMessages.alertContacts,
                  [index]: err.message,
                },
              }
            }
          }),
        )
      ).filter((alertContact) => alertContact)
    },

    async fetchLiveSetupForms() {
      this.loading = true

      try {
        this.liveSetupForms = await this.$services.liveSetupFormService.getAllForms()
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async fetchLiveSetupFormSubmissions(formId) {
      this.loading = true

      try {
        this.liveSetupFormSubmissions =
          await this.$services.liveSetupFormService.getAllFormSubmissions(formId)
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async fetchLiveSetupFormSubmission(id) {
      this.loading = true

      try {
        this.liveSetupFormSubmission = await this.$services.liveSetupFormService.getFormSubmission(
          id,
        )
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async refreshLiveSetupFormSubmission(id) {
      await this.fetchLiveSetupFormSubmission(id)
      this.resetLiveSetupFormSubmission()
      this.submissionErrorMessages = getInvalidSubmissionErrorMessages(this.liveSetupFormSubmission)
    },

    resetLiveSetupFormSubmission() {
      this.live = null
      this.liveSettings = null
      this.liveStages = []
      this.liveCategories = []
      this.liveAlertContacts = []
      this.submitErrorMessages = {}
    },
  },
}
</script>
