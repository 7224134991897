export class LiveRoleService {
  constructor(api) {
    this.api = api
  }

  async getAll(id) {
    return await this.api.get(`/lives/${id}/roles`, { id })
  }

  async update({ id, password }) {
    return await this.api.put(`/live_roles/${id}`, {
      id,
      password,
    })
  }

  async create({ id, role, password }) {
    return await this.api.post(`/lives/${id}/roles`, {
      id,
      role,
      password,
    })
  }
}
