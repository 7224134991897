<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-field grouped group-multiline class="columns">
      <b-field
        label="Nom"
        :message="fields.name.error"
        :type="fields.name.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-input v-model="fields.name.value" expanded type="text" />
      </b-field>
      <b-field
        label="Slug"
        :message="fields.slug.error"
        :type="fields.slug.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <p class="control">
          <span class="button is-static">/</span>
        </p>
        <b-input v-model="fields.slug.value" expanded type="text" />
      </b-field>
    </b-field>
    <b-field grouped group-multiline class="columns is-multiline">
      <b-field
        label="Date de début"
        :message="fields.startedAt.error"
        :type="fields.startedAt.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-datepicker
          v-model="fields.startedAt.value"
          placeholder="Type or select a date..."
          icon="calendar-day"
          :date-formatter="dateFormat"
        />
      </b-field>
      <b-field
        label="Date de fin"
        :message="fields.endedAt.error"
        :type="fields.endedAt.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-datepicker
          v-model="fields.endedAt.value"
          placeholder="Type or select a date..."
          icon="calendar-day"
          :date-formatter="dateFormat"
        />
      </b-field>
    </b-field>
    <b-field
      label="Fuseau horaire"
      :message="fields.timezone.error"
      :type="fields.timezone.error !== null ? 'is-danger' : ''"
    >
      <b-select
        v-model="fields.timezone.value"
        expanded
        placeholder="Choisissez un fuseau horaire"
        icon="globe"
      >
        <option v-for="timezone in timezones" :key="timezone" :value="timezone">
          {{ timezone }}
        </option>
      </b-select>
    </b-field>
    <b-field
      label="Accès visiteur"
      :message="fields.visitorAccessType.error"
      :type="fields.visitorAccessType.error !== null ? 'is-danger' : ''"
    >
      <b-field>
        <b-radio-button
          v-model="fields.visitorAccessType.value"
          native-value="public"
          type="is-success"
        >
          Public
        </b-radio-button>
        <b-radio-button
          v-model="fields.visitorAccessType.value"
          native-value="private"
          type="is-danger"
        >
          Privé
        </b-radio-button>
      </b-field>
    </b-field>
    <hr />
    <b-field>
      <b-button :disabled="disabledForm" native-type="submit" type="is-primary" :loading="loading">
        Modifier
      </b-button>
    </b-field>
  </form>
</template>

<script>
import { format } from 'date-fns'
import { mapActions } from 'vuex'
import { timezones } from '@models/timezone'
import { slugify } from '@helpers/slugify'

export default {
  name: 'EditLiveInformationsForm',

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      timezones,
      fields: {
        slug: {
          value: '',
          error: null,
        },
        name: {
          value: '',
          error: null,
        },
        startedAt: {
          value: null,
          error: null,
        },
        endedAt: {
          value: null,
          error: null,
        },
        timezone: {
          value: '',
          error: null,
        },
        visitorAccessType: {
          value: 'public',
          error: null,
        },
      },
    }
  },

  watch: {
    live: {
      handler: 'load',
      immediate: true,
    },
    'fields.slug.value'(value) {
      this.fields.slug.error =
        value !== slugify(value)
          ? 'Le slug ne doit contenir que des caractères alphanumériques, sans espaces (abc/-/123).'
          : null
    },
  },

  computed: {
    disabledForm() {
      return this.loading || Object.values(this.fields).some((field) => field.error !== null)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    load() {
      this.fields.name.value = this.live.name
      this.fields.slug.value = this.live.slug
      this.fields.startedAt.value = this.live.startedAt
      this.fields.endedAt.value = this.live.endedAt
      this.fields.timezone.value = this.live.timezone
      this.fields.visitorAccessType.value = this.live.visitorAccessType
    },

    async submit() {
      this.error = null
      this.loading = true

      try {
        const live = await this.$services.liveService.updateInformations(this.live.id, {
          name: this.fields.name.value,
          slug: this.fields.slug.value,
          startedAt: this.fields.startedAt.value,
          endedAt: this.fields.endedAt.value,
          timezone: this.fields.timezone.value,
          visitorAccessType: this.fields.visitorAccessType.value,
        })

        this.$emit('update:live', live)
        this.addToastMessage({
          text: `Le live "${live.name}" a été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.error = 'Une erreur interne est survenue.'
        console.error(err)
      }

      this.loading = false
    },

    dateFormat(date) {
      return format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
