<template>
  <form novalidate @submit.prevent="submit">
    <b-field
      style="width: 100%"
      expanded
      :label="liveRole.role.toUpperCase()"
      :message="fields.password.error"
      :type="fields.password.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.password.value" expanded type="password" password-reveal />
      <p class="control">
        <copy-button :textToCopy="fields.password.value" />
      </p>
      <p class="control">
        <b-button type="is-primary" :disabled="disabled" :loading="loading" native-type="submit">
          Modifier
        </b-button>
      </p>
    </b-field>
  </form>
</template>

<script>
import CopyButton from '@components/CopyButton.vue'
import { mapActions } from 'vuex'

export default {
  name: 'EditLiveRoleForm',

  components: {
    CopyButton,
  },

  props: {
    liveRole: {
      type: Object,
      required: true,
    },
    liveRoles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        password: {
          value: '',
          error: null,
        },
      },
    }
  },

  watch: {
    liveRole: {
      handler: 'init',
      immediate: true,
    },

    'fields.password.value'(password) {
      if (password.length > 0) {
        this.fields.password.error = this.liveRoles.find(
          (liveRole) => liveRole.id !== this.liveRole.id && liveRole.password === password,
        )
          ? 'Mot de passe déjà attributé'
          : null
      } else {
        this.fields.password.error = 'Mot de passe requis.'
      }
    },
  },

  computed: {
    disabled() {
      return (
        this.loading ||
        this.fields.password.error ||
        this.liveRole.password === this.fields.password.value
      )
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    init() {
      this.fields.password.value = this.liveRole.password
    },

    async submit() {
      this.loading = true

      try {
        const liveRole = await this.$services.liveRoleService.update({
          id: this.liveRole.id,
          password: this.fields.password.value,
        })

        this.$emit(
          'update:liveRoles',
          this.liveRoles.map((role) => (role.id === liveRole.id ? liveRole : role)),
        )

        this.addToastMessage({
          text: `Le mot de passe "${liveRole.role}" a été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>
