<template>
  <div>
    <b-loading :is-full-page="false" :active="loading" />
    <div class="is-flex is-justify-content-space-between is-align-items-center">
      <h2 class="title is-5 mb-0">Mots de passe d'accès</h2>
      <b-dropdown
        ref="addLiveForm"
        position="is-bottom-left"
        append-to-body
        aria-role="menu"
        :close-on-click="false"
      >
        <template #trigger>
          <b-button
            type="is-success"
            icon-left="plus"
            label="Ajouter un accès"
            :disabled="disabledAddLiveForm"
          />
        </template>
        <b-dropdown-item aria-role="menu-item" :focusable="false" custom>
          <add-live-role-form
            :live="live"
            :roles="availableRoles"
            :live-roles.sync="liveRoles"
            class="modal-card"
            @update:liveRoles="$refs.addLiveForm?.toggle()"
          />
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <hr />
    <edit-live-role-form
      v-for="liveRole in liveRoles"
      :key="liveRole.id"
      :live-role="liveRole"
      :live-roles.sync="liveRoles"
    />
  </div>
</template>

<script>
import EditLiveRoleForm from './EditLiveRoleForm.vue'
import AddLiveRoleForm from './AddLiveRoleForm.vue'
import { roles } from '@constants/live/role'

export default {
  name: 'EditLiveRolesForm',

  components: {
    AddLiveRoleForm,
    EditLiveRoleForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveRoles: [],
    }
  },

  watch: {
    live: {
      handler: 'load',
      immediate: true,
    },
  },

  computed: {
    availableRoles() {
      return roles.filter((role) => !this.liveRoles.some((lr) => lr.role === role))
    },

    disabledAddLiveForm() {
      return this.loading || this.availableRoles.length === 0
    },
  },

  methods: {
    async load() {
      this.loading = true

      try {
        this.liveRoles = await this.$services.liveRoleService.getAll(this.live.id)
      } catch (err) {
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>
