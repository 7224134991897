<template>
  <form novalidate @submit.prevent="submit">
    <b-field
      label="Role"
      :message="fields.role.error"
      :type="fields.role.error !== null ? 'is-danger' : ''"
    >
      <b-select v-model="fields.role.value" expanded placeholder="Choisissez un role" icon="user">
        <option v-for="role in roles" :key="role" :value="role">
          {{ role.toUpperCase() }}
        </option>
      </b-select>
    </b-field>
    <b-field
      label="Mot de passe"
      :message="fields.password.error"
      :type="fields.password.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.password.value" expanded type="password" password-reveal />
      <p class="control">
        <b-button type="is-primary" icon-right="sync" @click="initPassword" />
      </p>
    </b-field>
    <hr />
    <b-field>
      <b-button type="is-primary" :disabled="disabled" :loading="loading" native-type="submit">
        Ajouter
      </b-button>
    </b-field>
  </form>
</template>

<script>
import { roles } from '@constants/live/role'
import { mapActions } from 'vuex'

export default {
  name: 'AddLiveRoleForm',

  props: {
    live: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
      validator: (v) => v.every((role) => roles.indexOf(role) !== -1),
    },
    liveRoles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        role: {
          value: null,
          error: null,
        },
        password: {
          value: '',
          error: null,
        },
      },
    }
  },

  watch: {
    roles: {
      handler(roles) {
        if (roles.length) {
          this.fields.role.value = roles[0]
          this.initPassword()
        }
      },
      immediate: true,
    },

    'fields.password.value'(password) {
      if (password.length > 0) {
        this.fields.password.error = this.liveRoles.find(
          (liveRole) => liveRole.password === password,
        )
          ? 'Mot de passe déjà attributé'
          : null
      } else {
        this.fields.password.error = 'Mot de passe requis.'
      }
    },
  },

  computed: {
    disabled() {
      return this.loading || Object.values(this.fields).some((field) => field.error !== null)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        const liveRole = await this.$services.liveRoleService.create({
          id: this.live.id,
          role: this.fields.role.value,
          password: this.fields.password.value,
        })

        this.$emit('update:liveRoles', [...this.liveRoles, liveRole])

        this.addToastMessage({
          text: `Le role "${liveRole.role}" a été ajouté.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    initPassword() {
      this.fields.password.value = this.generatePassword()
    },

    generatePassword() {
      const password = Math.random().toString(36).substring(2, 10)
      if (this.liveRoles.find((liveRole) => liveRole.password === password)) {
        return this.generatePassword()
      }

      return password
    },
  },
}
</script>
