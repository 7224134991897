import { parseISO, format } from 'date-fns'
import { QUECLINK_TRACKER_TYPE, SPOT_TRACKER_TYPE } from '@constants/tracker/type.js'

export class LiveService {
  constructor(api) {
    this.api = api
  }

  async getAll() {
    return (await this.api.get('/lives')).map((live) => this.parse(live))
  }

  async getAllBySpotTrackerId(trackerId) {
    return (
      await this.api.get(`/spot_trackers/${trackerId}/lives`, {
        trackerType: SPOT_TRACKER_TYPE,
      })
    ).map((live) => this.parse(live))
  }

  async getAllByQueclinkTrackerId(trackerId) {
    return (
      await this.api.get(`/queclink_trackers/${trackerId}/lives`, {
        trackerType: QUECLINK_TRACKER_TYPE,
      })
    ).map((live) => this.parse(live))
  }

  async getById(id) {
    return this.parse(await this.api.get(`/lives/${id}`))
  }

  async create({ name, slug, startedAt, endedAt, timezone, visitorAccessType }) {
    return this.parse(
      await this.api.post('/lives', {
        name,
        slug,
        startedAt: format(startedAt, 'yyyy-MM-dd'),
        endedAt: format(endedAt, 'yyyy-MM-dd'),
        timezone,
        visitorAccessType,
      }),
    )
  }

  async updateInformations(
    id,
    { name, slug, startedAt, endedAt, timezone, visitorAccessType, comment, status },
  ) {
    return this.parse(
      await this.api.put(`/lives/${id}`, {
        name,
        slug,
        startedAt: format(startedAt, 'yyyy-MM-dd'),
        endedAt: format(endedAt, 'yyyy-MM-dd'),
        timezone,
        visitorAccessType,
        comment,
        status,
      }),
    )
  }

  async addLiveSponsor(id, image) {
    return this.parse(await this.api.post(`/lives/${id}/sponsors`, { image }))
  }

  parse(live) {
    live.startedAt = parseISO(live.startedAt)
    live.endedAt = parseISO(live.endedAt)

    return live
  }

  async delete(id) {
    return await this.api.delete(`/lives/${id}`)
  }
}
